import React, { useState } from "react";
import { Collapse, Button } from "react-bootstrap";

const NamesArray = ({ cell, row, confirmedBookings }) => {
  const [expand, setExpand] = useState(false);
  const handleClick = (e) => {
    e.stopPropagation();
    setExpand(!expand);
  };
  return (
    <>
      <Button
        onClick={handleClick}
        aria-controls="zz"
        aria-expanded={expand}
        variant={expand ? "outline-warning" : "outline-primary"}
        className={"mb-2"}
        style={{
          minWidth: "100px",
        }}
      >
        Show List
      </Button>
      <Collapse in={expand}>
        <div id="zz" className="card" style={{ width: "280px" }}>
          <table className="table table-borderless ">
            <thead>
              <th>Names</th>
              {confirmedBookings && <th>Receipts</th>}
            </thead>
            <tbody>
              {row.employees.map((employee, i) => {
                return (
                  <tr key={i}>
                    <td
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {employee.name}
                    </td>
                    {confirmedBookings && (
                      <td>
                        {employee.receipt && (
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              window.open(employee.receipt);
                            }}
                          >
                            View
                          </Button>
                        )}
                        {!employee.receipt && (
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            style={{ fontSize: "15px" }}
                            className="badge badge-warning"
                          >
                            Pending
                          </span>
                        )}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Collapse>
    </>
  );
};

export default NamesArray;
