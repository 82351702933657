import React, { Component } from "react";
import { Formik, ErrorMessage, FieldArray, Field } from "formik";
import Geosuggest from "react-geosuggest";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import { Header } from "@evenlogics/whf-ra-components";
import * as yup from "yup";
import { Row, Col } from "react-bootstrap";
import { Label, FormGroup, Input, Button } from "reactstrap";
import api from "@evenlogics/whf-api";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { TagsInput } from "react-tag-input-component";
import { isUndefined } from "lodash";

export default class Hotel extends Component {
  constructor(props) {
    super(props);
    this.geosuggestEl = React.createRef();
    this.state = {
      fields: {
        id: {
          value: "",
        },
        name: {
          value: "",
        },
        emails: {
          value: [],
        },
        tax_rate: {
          value: 0,
        },
        brand: {
          value: "",
        },
        address: {
          value: "",
        },
        brand_loyalty_number: {
          value: ""
        },
        latitude: {
          value: "",
        },
        longitude: {
          value: "",
        },
        hotel_rates: {
          value: [
            {
              rate: "",
              start_date: "",
              end_date: "",
            },
          ],
        },
      },

      schema: {
        rate: "",
        start_date: "",
        end_date: "",
      },
      loading: false,
      selected: []
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ ...this.state, loading: true });
      api
        .request("get", `/hotels/${this.props.match.params.id}`)
        .then((res) => {
          let updatedState = this.state;
          updatedState.fields.name.value = res.data.name;
          updatedState.fields.emails.value = res.data.emails.map((x) => x);
          updatedState.fields.tax_rate.value = res.data.tax_rate;
          updatedState.fields.brand.value = res.data.brand;
          updatedState.fields.brand_loyalty_number.value = res.data.brand_loyalty_number;
          updatedState.fields.hotel_rates.value = res.data.hotel_rates;
          updatedState.fields.address.value = res.data.address;
          updatedState.fields.latitude.value = res.data.latitude;
          updatedState.fields.longitude.value = res.data.longitude;

          updatedState.loading = false;
          this.setState({ ...updatedState });
          // console.log('res :>> ', res);
        })
        .catch((error) => {
          let updatedState = this.state;
          updatedState.loading = false;
          this.setState({ ...updatedState });
          // console.log('error :>> ', error);
        });
    }
  }



  handleInputChange = (e, setFieldValue) => {
    console.log(e, "eeee")
    let updatedState = { ...this.state };
    if (isUndefined(e.target)) {
      updatedState.fields["emails"].value = e;
      setFieldValue("emails", e)
    }
    else if (
      e.target.type === "text" ||
      e.target.type === "date" ||
      e.target.type === "number"
    ) {
      console.log("_________", e.target.name);
      updatedState.fields[e.target.name].value = e.target.value;
      setFieldValue(e.target.name, e.target.value)
    }

    else if (e.target.type === "geoSuggest") {
      if (e.target?.value?.description) {
        updatedState.fields.address.value = e.target.value.description;
        updatedState.fields.latitude.value =
          e.target.value.location.lat.toString();
        updatedState.fields.longitude.value =
          e.target.value.location.lng.toString();
        console.log(e.target.name);
        setFieldValue("address", e.target.value.description);
        setFieldValue("latitude", e.target.value.location.lat.toString());
        setFieldValue("longitude", e.target.value.location.lng.toString());
      }
    }

    this.setState({ ...updatedState }, () => { });
  };

  submit = (values) => {
    Swal.showLoading();
    const payload = {
      name: values.name,
      emails: values.emails,
      tax_rate: values.tax_rate,
      brand: values.brand,
      brand_loyalty_number: values.brand_loyalty_number,
      hotel_rates: values.hotel_rates,
      address: values.address,
      latitude: values.latitude,
      longitude: values.longitude,
    };

    let method = "post";
    let url = "/hotels";

    if (this.props.match.params.id) {
      method = "patch";
      url = `/hotels/${this.props.match.params.id}`;
    }

    api
      .request(method, url, payload)
      .then((res) => {
        toast.success("Hotel Saved!");
        Swal.close();
        this.props.history.push("/hotels/list");
      })
      .catch((error) => {
        this.showError(error);
        console.log("error :>> ", error);
      });
  };
  showError = (error) => {
    console.log(error.response.status);
    if (error.response.status === 422) {
      let { errors } = error.response.data;
      let errorMsg = "";
      Object.keys(errors).forEach((key) => {
        errorMsg = errorMsg + errors[key][0] + "\n";
      });
      Swal.fire("Validation Failed", errorMsg, "error");
    } else {
      Swal.fire("Opps! Something went wrong", "", "error");
    }
  };
  render() {
    const { fields } = this.state;

    const initialValues = {
      name: fields.name.value,
      emails: fields.emails.value,
      tax_rate: fields.tax_rate.value,
      brand: fields.brand.value,
      address: fields.address.value,
      brand_loyalty_number: fields.brand_loyalty_number.value,
      latitude: fields.latitude.value,
      longitude: fields.longitude.value,
      hotel_rates: fields.hotel_rates.value,
    };

    const validationSchema = () => {
      return yup.object().shape({
        name: yup.string().required("Name is required!"),
        emails: yup
          .string()
          // .email("Invalid Email Address")
          .required("Email is required!"),
        tax_rate: yup
          .number("tax rate can only be numbers")
          .min(0, "Tax rate cannot be negative")
          .required("Tax Rate is required!"),
        brand: yup.string().required("Brand is required!"),
        address: yup.string().required("Address is required!"),
        brand_loyalty_number: yup.string().required("Brand Loyality Number is required"),
        latitude: yup.string().required("latitude is required!"),
        longitude: yup.string().required("longitude is required!"),
        hotel_rates: yup.array().of(
          yup.object().shape({
            rate: yup
              .number("Rate can only be numbers")
              .min(0, "Tax rate cannot be negative")
              .required("Rate is required!"),
            start_date: yup.string().required("Start date is required"),
            end_date: yup.string().required("End date is required"),
          })
        ),
      });
    };

    const { loading } = this.state;
    return (
      <div>
        <Card className="animated fadeIn">
          <Header title="Hotels" />
          <CardBody>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={this.submit}
              enableReinitialize={true}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label for="name"> Name </Label>
                        <Input
                          className={loading ? "placeholder-wave bg-light" : ""}
                          id="name"
                          name="name"
                          placeholder=""
                          type="text"
                          value={values.name}
                          onChange={(e) =>
                            this.handleInputChange(e, setFieldValue)
                          }
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="name" />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label for="emails"> Email </Label>
                        <TagsInput
                          className={loading ? "placeholder-wave bg-light" : ""}
                          id="emails"
                          name="emails"
                          placeHolder="Press Enter for Adding Email"
                          //type="text"
                          value={values.emails}
                          onChange={(e) =>
                            this.handleInputChange(e, setFieldValue)
                          }
                        />
                        {/* <Input
                          className={loading ? "placeholder-wave bg-light" : ""}
                          id="emails"
                          name="emails"
                          placeholder=""
                          type="text"
                          value={values.emails}
                          onChange={(e) =>
                            this.handleInputChange(e, setFieldValue)
                          }
                        /> */}
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="emails" />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col md="6">
                      <FormGroup>
                        <Label for="tax_rate"> Tax Rate (%) </Label>
                        <Input
                          className={loading ? "placeholder-wave bg-light" : ""}
                          style={{ textAlign: "left" }}
                          id="tax_rate"
                          name="tax_rate"
                          placeholder=""
                          type="number"
                          step={"any"}
                          value={values.tax_rate}
                          onChange={(e) =>
                            this.handleInputChange(e, setFieldValue)
                          }
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="tax_rate" />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label for="brand"> Brand </Label>
                        <Input
                          className={loading ? "placeholder-wave bg-light" : ""}
                          id="brand"
                          name="brand"
                          placeholder=""
                          type="text"
                          value={values.brand}
                          onChange={(e) =>
                            this.handleInputChange(e, setFieldValue)
                          }
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="brand" />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col md="6">
                      <FormGroup>
                        <Label for="address"> Address </Label>
                        <Geosuggest
                          initialValue={values.address}
                          ref={this.geosuggestEl}
                          onSuggestSelect={(val) => {
                            this.geosuggestEl.current.blur();
                            this.handleInputChange(
                              {
                                target: {
                                  type: "geoSuggest",
                                  value: val,
                                  name: "address",
                                },
                              },
                              setFieldValue
                            );
                            console.log(val);
                          }}
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="address" />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col md="6">
                      <FormGroup>
                        <Label for="brand_loyalty_number"> Brand Loyality Number(Hotel) </Label>
                        <Input
                          className={loading ? "placeholder-wave bg-light" : ""}
                          id="brand_loyalty_number"
                          name="brand_loyalty_number"
                          placeholder=""
                          type="text"
                          value={values.brand_loyalty_number}
                          onChange={(e) =>
                            this.handleInputChange(e, setFieldValue)
                          }
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="brand_loyalty_number" />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <div
                    className="card p-4 "
                    style={{ border: "1px solid #dbdbdb" }}
                  >
                    <FieldArray
                      name="hotel_rates"
                      render={({ insert, remove, push }) => (
                        <div className={"mb-1"}>
                          {values.hotel_rates.length > 0 &&
                            values.hotel_rates.map((hotel_rate, index) => (
                              <div
                                className="row mb-3 d-flex align-items-center"
                                key={index}
                              >
                                <div className="col-md-3">
                                  <label htmlFor={`hotel_rate.${index}.rate`}>
                                    {" "}
                                    Rate ($){" "}
                                  </label>
                                  <Field
                                    className="form-control"
                                    name={`hotel_rates.${index}.rate`}
                                    placeholder="rate"
                                    type="number"
                                    step={"any"}
                                    style={{ textAlign: "left" }}
                                  />
                                  <div style={{ color: "red" }}>
                                    <ErrorMessage
                                      name={`hotel_rates.${index}.rate`}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <label
                                    htmlFor={`hotel_rate.${index}.start_date`}
                                  >
                                    {" "}
                                    Start Date{" "}
                                  </label>
                                  <Field
                                    className="form-control"
                                    name={`hotel_rates.${index}.start_date`}
                                    placeholder="start date"
                                    type="date"
                                  />
                                  <div style={{ color: "red" }}>
                                    <ErrorMessage
                                      name={`hotel_rates.${index}.start_date`}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-3">
                                  <label
                                    htmlFor={`hotel_rate.${index}.end_date`}
                                  >
                                    {" "}
                                    End Date{" "}
                                  </label>
                                  <Field
                                    className="form-control"
                                    name={`hotel_rates.${index}.end_date`}
                                    placeholder="end date"
                                    type="date"
                                  />
                                  <div style={{ color: "red" }}>
                                    <ErrorMessage
                                      name={`hotel_rates.${index}.end_date`}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-2 mt-4">
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => remove(index)}
                                  >
                                    <i
                                      class="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>
                            ))}
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => push(this.state.schema)}
                          >
                            Add Rates
                          </button>
                        </div>
                      )}
                    />
                  </div>

                  <br />

                  <Button type="submit" color="success">
                    Submit
                  </Button>
                  {false && (
                    <div className={"row"}>
                      <div className={"col-12"}>
                        <code>
                          <pre>Values: {JSON.stringify(values, null, 2)}</pre>
                        </code>
                      </div>
                      <div className={"col-12"}>
                        <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
                      </div>
                      <div className={"col-12"}>
                        <pre>Touched: {JSON.stringify(touched, null, 2)}</pre>
                      </div>
                    </div>
                  )}
                </form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </div>
    );
  }
}
