export default {
  // dashboard: {
  // 	_tag: 'CSidebarNavItem',
  // 	name: 'Dashboard',
  // 	phrase: 'dashboard',
  // 	to: '/',
  // 	order: 1,
  // 	icon: 'fas fa-tachometer-alt',
  // 	badge: {
  // 		color: 'info',
  // 		text: 'NEW'
  // 	}
  // },

  hotels: {
    _tag: "CSidebarNavItem",
    name: "Hotels",
    phrase: "Hotels",
    to: "/hotels/list",
    order: 2,
    icon: "fas fa-hotel",
  },

  employees: {
    _tag: "CSidebarNavItem",
    name: "Employees",
    phrase: "Employees",
    to: "/employees/list",
    order: 3,
    icon: "fa fa-users",
  },
  availabilityForm: {
    _tag: "CSidebarNavItem",
    name: "availabilityRequests",
    phrase: "Availability Requests",
    to: "/availability-form/list",
    order: 4,
    icon: "fa fa-window-maximize",
  },
  confirmedBookings: {
    _tag: "CSidebarNavItem",
    name: "Confirmed Bookings",
    phrase: "Confirmed Bookings",
    to: "/bookings/list",
    order: 5,
    icon: "fas fa-calendar-check",
  },
  emailTemplates: {
    _tag: "CSidebarNavItem",
    name: "emailTemplates",
    phrase: "Email Templates",
    to: "/email-templates/list",
    order: 6,
    icon: "fa fa-envelope",
  },
  smsTemplates: {
    _tag: "CSidebarNavItem",
    name: "smsTemplates",
    phrase: "SMS Templates",
    to: "/messages/list",
    order: 6,
    icon: "fa fa-message",
  },
  settings: {
    _tag: "CSidebarNavItem",
    name: "settings",
    phrase: "Settings",
    to: "/settings",
    order: 7,
    icon: "fa fa-cog",
  },
  archives: {
    _tag: "CSidebarNavItem",
    name: "archives",
    phrase: "Archives",
    to: "/archives",
    order: 8,
    icon: "fa fa-archive",
    children: {
      hotels: {
        _tag: "CSidebarNavItem",
        name: "hotels",
        phrase: "Hotels",
        to: "/archives/hotels",
        order: 1,
        icon: "fas fa-hotel",
      },
      bookings: {
        _tag: "CSidebarNavItem",
        name: "bookings",
        phrase: "Bookings",
        to: "/archives/bookings",
        order: 2,
        icon: "fas fa-calendar-check",
      },
    },
  },
};
