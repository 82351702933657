import React, { useState, Component } from "react";
import RemoteTable from "@evenlogics/whf-remote-table";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import { Header } from "@evenlogics/whf-ra-components";
import Badge from "react-bootstrap/Badge";
import { Button } from "react-bootstrap";
import api from "@evenlogics/whf-api";
import Swal from "sweetalert2";

const Status = ({ sCell, sRow, index }) => {
  const [row, setRow] = useState(sRow);
  const terminateEmp = (id) => {
    Swal.fire({
      title: "Are you sure, you want terminate?",
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        let timerInterval;
        Swal.fire({
          title: "Sending request...",
          html: "Please wait until <b> </b> request is sent",
          // timer: 2000,
          // timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            const b = Swal.getHtmlContainer().querySelector("b");
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft();
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        });
        api
          .request("post", "/employees/" + id + "/terminate")
          .then((res) => {
            Swal.fire("Terminated", res.message, "success");
            setRow({ ...row, status: 1 });
          })
          .catch(() => {
            Swal.fire("Couldn't Terminate", "Something went wrong!", "error");
          });
        // }, 0);
      } else if (result.isDenied) {
      }
    });
  };
  return (
    <>
      {row?.status === 2 && (
        <Button
          variant="warning"
          size="sm"
          onClick={() => terminateEmp(row.id)}
        >
          Terminate
        </Button>
      )}
      {row?.status === 1 && (
        <Badge pill bg="danger" className="text-white">
          Terminated
        </Badge>
      )}
    </>
  );
};

export default class List extends Component {
  render() {
    const columns = [
      { dataField: "id", text: "Id", align: "center", sort: true },
      { dataField: "name", text: "Name", align: "center", sort: true },
      { dataField: "email", text: "Email", align: "center", sort: true },
      { dataField: "phone", text: "Phone", align: "center", sort: true },
      { dataField: "address", text: "Address", align: "center", sort: true },

      {
        dataField: "recruiter_name",
        text: "Recruiter Name",
        align: "center",
        sort: true,
      },
      {
        dataField: "recruiter_email",
        text: "Recruiter Email",
        align: "center",
        sort: true,
      },

      // { dataField: 'check_in', text: 'Check In', align: 'center', sort: true },
      // { dataField: 'check_out', text: 'Check Out', align: 'center', sort: true },

      // {dataField: 'contract_start', text: 'Contract Start', align: 'center', sort: true },
      // { dataField: 'contract_end', text: 'Contract End', align: 'center', sort: true },

      {
        dataField: "termination",
        text: "Termination",
        align: "center",
        formatter: (cell, row, index) => {
          return <Status sCell={cell} sRow={row} index={index} />;
        },
      },
    ];

    const defaultSorted = [
      {
        dataField: "id",
        order: "desc",
      },
    ];

    return (
      <div>
        <Card className="animated fadeIn">
          <Header title="Employees" />
          <CardBody>
            <RemoteTable
              entity="employees"
              customEntity="employees"
              columns={columns}
              sort={defaultSorted}
              showDetail={false}
              hideDetail={true}
              // hideSearch={true}
              addRoute={"/employees/add"}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}
