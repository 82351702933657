import React, { Component } from 'react'
import RemoteTable from '@evenlogics/whf-remote-table';
import Card from 'reactstrap/lib/Card'
import CardBody from 'reactstrap/lib/CardBody';
import { Header } from '@evenlogics/whf-ra-components';

export default class List extends Component {



  render() {

    const columns = [
      { dataField: 'id', text: 'Id', align: 'center', sort: true },
      { dataField: 'title', text: 'Title', align: 'center', sort: true },
  ];

  const defaultSorted = [
      {
          dataField: 'id',
          order: 'asc'
      }
  ];

    return (
      <div>
        
        <Card className="animated fadeIn">
                    <Header title="Templates" />
                    <CardBody>
                        <RemoteTable
                            entity="emails"
                            customEntity="email-templates"
                            columns={columns}
                            sort={defaultSorted}
                            showDetail={false}
                            disableDelete={true}
                            hideDetail={true}
                            // hideSearch={true}
                        // addRoute={"/emails/add"}
                        />
                    </CardBody>
                </Card>
      </div>
    )
  }
}
