import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import { FormGenerator } from "@evenlogics/whf-form-generator";
import api from "@evenlogics/whf-api";
import { toast } from "react-toastify";
import "./CheckoutReceipt.css";

const CheckoutReceipt = () => {
  const [bookingsId, setBookingsId] = useState("");
  const [employeesId, setEmployeesId] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    const base64 = window.location.href.split("/").pop();
    const atob = (base64) => {
      return Buffer.from(base64, "base64").toString("binary");
    };
    const decoded = atob(base64);

    if (
      decoded.includes("bookings") &&
      decoded.includes("employees") &&
      !isNaN(decoded.split("/")[1]) &&
      !isNaN(decoded.split("/")[3]) &&
      decoded.split("/")[1] !== "" &&
      decoded.split("/")[3] !== ""
    ) {
      setBookingsId(decoded.split("/")[1]);
      setEmployeesId(decoded.split("/")[3]);
    } else {
      toast.error("Invalid URL");
    }
  }, []);

  useEffect(() => {
    if (bookingsId && employeesId) {
      let method = "get";
      let url =
        "/public/bookings/" +
        bookingsId +
        "/employees/" +
        employeesId +
        "/reciept";

      api
        .request(method, url)
        .then((res) => {
          console.log("reciept checked: ", res);
          setShow(true);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          console.log("errorssss :>> ", error);
        });
    }
  }, [bookingsId, employeesId]);

  let fields = {
    name: {
      type: "text",
      label: "Name",
      required: true,
      name: "name",
      col: 12 + " col-lg-4 col-md-6 ",
      placeholder: "Enter Employee Name",
      className: "form-control-lg",
    },
    hotel: {
      type: "text",
      label: "Hotel",
      required: true,
      name: "hotel",
      col: 12 + " col-lg-4 col-md-6 ",
      placeholder: "Enter Hotel Name",
      className: "form-control-lg",
    },
    check_out: {
      type: "date",
      label: "Check-Out Date",
      required: true,
      name: "check_out",
      col: 12 + " col-lg-4 col-md-6 ",
      placeholder: "Enter Date",
      className: "form-control-lg",
    },

    receipt: {
      type: "file",
      label: "Upload",
      name: "receipt",
      col: 12 + " col-lg-4 col-md-6 ",
      required: true,
      className: "form-control-lg",
    },
  };
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: "9999",
        margin: "0",
        padding: "1rem",
        backgroundColor: "white",
      }}
    >
      {show && (
        <>
          <Card
            className="animated fadeIn globalCard"
            style={{ padding: "1rem", margin: "3rem" }}
          >
            <h3> Upload Receipt </h3>

            <FormGenerator
              targetEntity={`public/checkout-receipts`}
              fields={fields}
              name="responses"
              redirect="thankyou"
              extraVals={{ booking_id: bookingsId, employee_id: employeesId }}
            />
            {/* make a bootstrap form for name, date, and upload */}
            {/* to send extra values in the body, you can use extraVals param in FormGenerator; to send url parameters, you can use queryParams param in FormGenerator perhaps. */}
          </Card>
        </>
      )}
    </div>
  );
};

export default CheckoutReceipt;
