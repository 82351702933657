import React from "react";
import List from "../Bookings/List";

const BookingArchives = () => {
  return (
    <>
      <List archives={true} />
    </>
  );
};

export default BookingArchives;
