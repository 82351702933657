import React, { Component } from "react";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import { Header } from "@evenlogics/whf-ra-components";
import { FormGenerator } from "@evenlogics/whf-form-generator";

export default class Hotel extends Component {
  render() {
    const { id } = this.props.match.params;
    const fields = {
      title: {
        type: "text",
        label: "Title",
        required: true,
        name: "title",
        col: 12,
        disabled: true,
      },
      subject: {
        type: "text",
        label: "Subject",
        required: true,
        name: "subject",
        col: 12,
      },
      description: {
        type: "wysiwyg",
        label: "Description",
        required: true,
        name: "description",
        col: 12,
      },
    };

    return (
      <div>
        <Card className="animated fadeIn">
          <Header title="Employees" />
          <CardBody>
            <FormGenerator
              // targetSubmit={(values, actions, filesSet) => {
              //   alert()
              //   console.log(values, actions, filesSet);
              // }}
              targetEntity="emails"
              fields={fields}
              targetId={id}
              name="emails"
              // debug={true}
              redirect="email-templates/list"
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}
