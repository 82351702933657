import React, { useState, useEffect } from "react";
import { CardBody, Button } from "reactstrap";
import { Card } from "react-bootstrap";
import { Alert } from "react-bootstrap";
import { toast } from "react-toastify";
import api from "@evenlogics/whf-api";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "react-modern-calendar-datepicker";

function useForceUpdate() {
  const [, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

const PartialAvailability = (props) => {
  const forceUpdate = useForceUpdate();
  const [dates, setDates] = useState([]);
  const [showContent, setShowContent] = useState(false);
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);

  const minimumDate = {
    year: minDate?.getFullYear(),
    month: minDate?.getMonth() + 1,
    day: minDate?.getDate(),
  };
  const maximumDate = {
    year: maxDate?.getFullYear(),
    month: maxDate?.getMonth() + 1,
    day: maxDate?.getDate(),
  };
  const defaultRange = {
    from: "",
    to: "",
  };

  const [selectedDayRange, setSelectedDayRange] = useState(defaultRange);

  useEffect(() => {
    let url = props.match.url;
    // extracting the response
    let urlArray = url.split("/");
    let hotelsId = urlArray[urlArray.length - 3];
    let bookingsId = urlArray[urlArray.length - 5];
    api
      .request(
        "get",
        `/public/bookings/${bookingsId}/hotels/${hotelsId}/response`
      )
      .then(() => {
        setVisible(true);
        setShowContent(true);
      })
      .catch((err) => {
        if (err.response?.status === 404) {
          setVisible(false);
          // toast.error("Invalid Request");
          setMessage("Invalid Request");
          setShowContent(true);
        }
        if (err.response?.status === 422) {
          setVisible(false);
          toast.error(err.response.data.message);
          setShowContent(true);
          setMessage(err.response.data.message);
        }
      });
  }, [props.match.url]);
  useEffect(() => {
    if (visible) {
      let url = props.match.url;
      let urlArray = url.split("/");
      let bookingsId = urlArray[urlArray.length - 5];
      api
        .request("get", `/public/bookings/${bookingsId}/dates`)
        .then((res) => {
          // converting the dates to date format
          let min = new Date(res?.dates.check_in);
          let max = new Date(res?.dates.check_out);
          setMinDate(min);
          setMaxDate(max);
        })
        .catch((err) => {
          if (err.response?.status === 404) {
            setVisible(false);
            // toast.error("Invalid Request");
            setMessage("Invalid Request");
            setShowContent(true);
          }
          if (err.response?.status === 422) {
            setVisible(false);
            toast.error(err.response.data.message);
            setShowContent(true);
            setMessage(err.response.data.message);
          }
        });
    }
  }, [visible, setVisible, props.match.url]);

  const onChange = (date) => {
    setSelectedDayRange(date);
    let { from, to } = date;
    let check = 0;
    if (from?.day && to?.day) {
      from = new Date(from.year, from.month - 1, from.day + 1);
      to = new Date(to.year, to.month - 1, to.day + 1);
      if (dates.length === 0) {
        setDates([...dates, { start_date: from, end_date: to }]);
      } else {
        dates.forEach((date) => {
          if (
            (from.getTime() >= date.start_date.getTime() &&
              from.getTime() <= date.end_date.getTime()) ||
            (to.getTime() >= date.start_date.getTime() &&
              to.getTime() <= date.end_date.getTime()) ||
            (date.start_date.getTime() >= from.getTime() &&
              date.start_date.getTime() <= to.getTime()) ||
            from.getTime() === date.start_date.getTime()
          ) {
            check += 1;
            console.log("check added.");
          } else {
            console.log("check did not add.");
          }
          //if start_date is equal or within the range of from and to dates
        });
        if (check === 0) {
          setDates([...dates, { start_date: from, end_date: to }]);
        } else {
          toast.error("Date already selected");
        }
      }
    }
  };
  const handleSubmit = () => {
    // changing the dates in dates array to the format that the api expects
    let url = props.match.url;
    // extracting the response
    let urlArray = url.split("/");
    let hotelsId = urlArray[urlArray.length - 3];
    let bookingsId = urlArray[urlArray.length - 5];
    const datesToSubmit = dates.map((date) => {
      return {
        start_date: date.start_date.toISOString().slice(0, 10),
        end_date: date.end_date.toISOString().slice(0, 10),
      };
    });
    api
      .request("POST", `/public/bookings/response/partial`, {
        dates: datesToSubmit,
        booking_id: bookingsId,
        hotel_id: hotelsId,
      })
      .then((res) => {
        setVisible(false);
        toast.success(res.message);
        setShowContent(false);
        props.props.history.push("/thankyou");
      })
      .catch((err) => {
        console.log("err: ", err);
        if (err?.response?.data?.errors) {
          err.response.data.errors.forEach((error) => {
            toast.error(error.message);
          });
        } else {
          toast.error(err?.response?.data?.message);
        }
      });
  };
  return (
    <>
      {visible && showContent && (
        <Card style={{ padding: "1rem", height: "100%" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Card.Title>Partial Availability Selection</Card.Title>
            <Button variant="success" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
          <hr />
          <CardBody>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "15px",
              }}
            >
              <div>
                <Card.Text style={{ width: "250px", wordWrap: "break-word" }}>
                  Please select the days when you're available to accommodate
                  this booking request.{" "}
                  {minimumDate.year && (
                    <>
                      {" "}
                      <span style={{ fontWeight: "bold" }}>
                        Available Dates range:{" "}
                      </span>{" "}
                      <br />
                      <span
                        style={{
                          display: "inline",
                          color: "green",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        {`${minimumDate?.year?.toString()}/${minimumDate?.month?.toString()}/${minimumDate?.day?.toString()}`}{" "}
                        -{" "}
                        {`${maximumDate?.year?.toString()}/${maximumDate?.month?.toString()}/${maximumDate?.day?.toString()}`}
                      </span>
                    </>
                  )}
                </Card.Text>
                {minDate !== null && (
                  <Calendar
                    value={selectedDayRange}
                    onChange={onChange}
                    minimumDate={minimumDate}
                    maximumDate={maximumDate}
                  />
                )}
              </div>
              <div
                style={{
                  flex: "1",
                  justifyContent: "center",
                  marginLeft: "20px",
                }}
              >
                {dates.length > 0 && (
                  <Card.Title
                    style={{
                      marginLeft: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Selected Dates
                  </Card.Title>
                )}
                <div
                  style={{
                    marginLeft: "10px",
                    marginTop: "25px",
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "scroll",
                    height: "500px",
                  }}
                >
                  {/* showing the start and end dates selected by ReactDatePicker with delete option */}
                  {dates.map((date, i) => {
                    if (date.start_date && date.end_date) {
                      return (
                        <Card style={{ padding: "1rem" }} key={i}>
                          <span style={{ display: "inline-block" }}>
                            {date.start_date.getTime() !==
                            date.end_date.getTime() ? (
                              <>
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginRight: "8px",
                                  }}
                                >
                                  {" "}
                                  Start:{" "}
                                  {new Date(
                                    date.start_date - 1
                                  ).toLocaleDateString()}{" "}
                                </span>
                                <span style={{ fontWeight: "bold" }}>
                                  {" "}
                                  End:{" "}
                                  {new Date(
                                    date.end_date - 1
                                  ).toLocaleDateString()}
                                </span>
                              </>
                            ) : (
                              <>
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginRight: "8px",
                                  }}
                                >
                                  {" "}
                                  Date:{" "}
                                  {new Date(
                                    date.start_date - 1
                                  ).toLocaleDateString()}{" "}
                                </span>
                              </>
                            )}
                            <button
                              type="button"
                              className="btn btn-danger"
                              style={{ marginLeft: "20px" }}
                              onClick={() => {
                                dates.splice(dates.indexOf(date), 1);
                                setSelectedDayRange(defaultRange); // resetting the date range
                                forceUpdate();
                              }}
                            >
                              <i className="fa fa-trash" aria-hidden="true"></i>
                            </button>
                          </span>
                        </Card>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      )}
      {!visible && showContent && (
        <Alert variant="danger">
          <Alert.Heading>Error</Alert.Heading>
          <p>{message}</p>
        </Alert>
      )}
    </>
  );
};

export default PartialAvailability;
