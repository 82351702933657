import React, { useEffect, useState } from "react";
import Dialog from "./Dialog";
import PartialAvailability from "./PartialAvailability";

const Confirmation = (props) => {
  const [confirm, setConfirm] = useState("");
  useEffect(() => {
    let url = props.match.url;
    // extracting the response
    let urlArray = url.split("/");
    let responseName = urlArray[urlArray.length - 1];
    setConfirm(responseName);
  }, [props.match.url]);

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: "9999",
        margin: "0",
        padding: "2rem",
        backgroundColor: "white",
      }}
    >
      {confirm === "yes" || confirm === "no" ? (
        <Dialog props={props} match={props.match} />
      ) : confirm === "partial" ? (
        <PartialAvailability props={props} match={props.match} />
      ) : (
        ""
      )}
    </div>
  );
};

export default Confirmation;
