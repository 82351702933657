import React, { Component } from "react";
import { Formik, ErrorMessage } from "formik";
import Geosuggest from "react-geosuggest";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import { Header } from "@evenlogics/whf-ra-components";
import * as yup from "yup";
import { Row, Col } from "react-bootstrap";
import { Label, FormGroup, Input, Button } from "reactstrap";
import api from "@evenlogics/whf-api";
// import phone from "phone";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import "./Add.css";

export default class Hotel extends Component {
  constructor(props) {
    super(props);
    this.geosuggestEl = React.createRef();
    this.state = {
      fields: {
        name: {
          value: "",
        },
        email: {
          value: "",
        },
        address: {
          value: "",
        },
        longitude: {
          value: "",
        },
        latitude: {
          value: "",
        },
        phone: {
          value: "",
        },
        recruiter_name: {
          value: "",
        },
        recruiter_email: {
          value: "",
        },
        check_in: {
          value: "",
        },
        check_out: {
          value: "",
        },
        contract_start: {
          value: "",
        },

        contract_end: {
          value: "",
        },
      },
      loading: false,
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ ...this.state, loading: true });
      api
        .request("get", `/employees/${this.props.match.params.id}`)
        .then((res) => {
          let updatedState = this.state;
          updatedState.fields.name.value = res.data.name;
          updatedState.fields.email.value = res.data.email;
          updatedState.fields.phone.value = res.data.phone;

          updatedState.fields.recruiter_name.value = res.data.recruiter_name;
          updatedState.fields.recruiter_email.value = res.data.recruiter_email;

          updatedState.fields.check_in.value = res.data.check_in;
          updatedState.fields.check_out.value = res.data.check_out;

          updatedState.fields.contract_start.value = res.data.contract_start;
          updatedState.fields.contract_end.value = res.data.contract_end;

          updatedState.fields.address.value = res.data.address;
          updatedState.fields.latitude.value = res.data.latitude;
          updatedState.fields.longitude.value = res.data.longitude;

          updatedState.loading = false;
          this.setState({ ...updatedState });
          // console.log('res :>> ', res);
        })
        .catch((error) => {
          let updatedState = this.state;
          updatedState.loading = false;
          this.setState({ ...updatedState });
          // console.log('error :>> ', error);
        });
    }
  }

  handleInputChange = (e, setFieldValue) => {
    let updatedState = { ...this.state };
    if (
      e.target.type === "text" ||
      e.target.type === "date" ||
      e.target.type === "number"
    ) {
      console.log("_________", e.target.name);
      updatedState.fields[e.target.name].value = e.target.value;
      setFieldValue(e.target.name, e.target.value);
    }

    if (e.target.type === "geoSuggest") {
      if (e.target?.value?.description) {
        updatedState.fields.address.value = e.target.value.description;
        updatedState.fields.latitude.value =
          e.target.value.location.lat.toString();
        updatedState.fields.longitude.value =
          e.target.value.location.lng.toString();
        console.log(e.target.name, "name");
          setFieldValue("address", e.target.value.description);
          setFieldValue("latitude", e.target.value.location.lat.toString());
          setFieldValue("longitude", e.target.value.location.lng.toString());
      } else{
        console.log("elseee")
        setFieldValue("address", "");
        setFieldValue("latitude","");
        setFieldValue("longitude", "");
      }
    }

    this.setState({ ...updatedState }, () => {});
  };
  submit = (values) => {
    Swal.showLoading();
    // let phoneRes = phone(values.phone, { country: null });
    // if (phoneRes.isValid) {
    //   values.phone = phoneRes.phoneNumber;
    // } else {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Error",
    //     text: "Phone number is invalid",
    //   });
    //   return;
    // }

    const payload = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      recruiter_name: values.recruiter_name,
      recruiter_email: values.recruiter_email,
      check_in: values.check_in,
      check_out: values.check_out,
      contract_start: values.contract_start,
      contract_end: values.contract_end,
      address: values.address,
      latitude: values.latitude,
      longitude: values.longitude,
    };

    let method = "post";
    let url = "/employees";

    if (this.props.match.params.id) {
      method = "patch";
      url = `/employees/${this.props.match.params.id}`;
    }

    api
      .request(method, url, payload)
      .then((res) => {
        // Swal.fire({ title: 'Saved!', icon: 'success' })
        toast.success("Employee Saved!");
        Swal.close();
        this.props.history.push("/employees/list");
      })
      .catch((error) => {
        this.showError(error);
        console.log("error :>> ", error);
      });
  };
  showError = (error) => {
    console.log(error.response.status);
    if (error.response.status === 422) {
      let { errors } = error.response.data;
      let errorMsg = "";
      Object.keys(errors).forEach((key) => {
        errorMsg = errorMsg + errors[key][0] + "\n";
      });
      Swal.fire("Validation Failed", errorMsg, "error");
    } else {
      Swal.fire("Opps! Something went wrong", "", "error");
    }
  };
  render() {
    const { fields } = this.state;

    const initialValues = {
      name: fields.name.value,
      email: fields.email.value,
      phone: fields.phone.value,
      recruiter_name: fields.recruiter_name.value,
      recruiter_email: fields.recruiter_email.value,
      check_in: fields.check_in.value,
      check_out: fields.check_out.value,
      contract_start: fields.contract_start.value,
      contract_end: fields.contract_end.value,

      address: fields.address.value,
      latitude: fields.latitude.value,
      longitude: fields.longitude.value,
    };

    const validationSchema = () => {
      return yup.object().shape({
        name: yup.string().required("Name is required!"),
        email: yup
          .string()
          .email("Invalid Email Address")
          .required("Email is required!"),
        phone: yup
          .number()
          .min(0, "Phone number cannot have - in it")
          .required("Phone is required!"),
        recruiter_name: yup.string().required("Recruiter Name is required!"),
        recruiter_email: yup
          .string()
          .email("Invalid Email Address")
          .required("Recruiter email is required!"),
        check_in: yup.string().required("Check in is required!"),
        check_out: yup.string().required("Check out is required!"),
        contract_start: yup.string().required("Contract start is required!"),
        contract_end: yup.string().required("Contract end is required!"),

        address: yup.string().required("Address is required!"),
        latitude: yup.string().required("latitude is required!"),
        longitude: yup.string().required("longitude is required!"),
      });
    };

    const { loading } = this.state;
    return (
      <div>
        <Card className="animated fadeIn">
          <Header title="Employees" />
          <CardBody>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={this.submit}
              enableReinitialize={true}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <Label for="name"> Name* </Label>
                        <Input
                          className={loading ? "placeholder-wave bg-light" : ""}
                          id="name"
                          name="name"
                          placeholder=""
                          type="text"
                          value={values.name}
                          onChange={(e) =>
                            this.handleInputChange(e, setFieldValue)
                          }
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="name" />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label for="email"> Email* </Label>
                        <Input
                          className={loading ? "placeholder-wave bg-light" : ""}
                          id="email"
                          name="email"
                          placeholder=""
                          type="text"
                          value={values.email}
                          onChange={(e) =>
                            this.handleInputChange(e, setFieldValue)
                          }
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="email" />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col md="4">
                      <FormGroup>
                        <Label for="address"> Address* </Label>
                        <Geosuggest
                          initialValue={values.address}
                          ref={this.geosuggestEl}
                          onSuggestSelect={(val) => {
                            this.geosuggestEl.current.blur();
                            this.handleInputChange(
                              {
                                target: {
                                  type: "geoSuggest",
                                  value: val,
                                  name: "address",
                                },
                              },
                              setFieldValue
                            );
                            console.log(val);
                          }}
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="address" />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col md="4">
                      <FormGroup>
                        <Label for="phone"> Phone* </Label>
                        <Input
                          className={
                            loading
                              ? "placeholder-wave bg-light no-counter"
                              : ""
                          }
                          style={{ textAlign: "left" }}
                          id="phone"
                          name="phone"
                          placeholder=""
                          type="number"
                          min={0}
                          value={values.phone}
                          onChange={(e) =>
                            this.handleInputChange(e, setFieldValue)
                          }
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="phone" />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col md="4">
                      <FormGroup>
                        <Label for="recruiter_name"> Recruiter Name* </Label>
                        <Input
                          className={loading ? "placeholder-wave bg-light" : ""}
                          id="recruiter_name"
                          name="recruiter_name"
                          placeholder=""
                          type="text"
                          value={values.recruiter_name}
                          onChange={(e) =>
                            this.handleInputChange(e, setFieldValue)
                          }
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="recruiter_name" />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col md="4">
                      <FormGroup>
                        <Label for="recruiter_email"> Recruiter Email* </Label>
                        <Input
                          className={loading ? "placeholder-wave bg-light" : ""}
                          id="recruiter_email"
                          name="recruiter_email"
                          placeholder=""
                          type="text"
                          value={values.recruiter_email}
                          onChange={(e) =>
                            this.handleInputChange(e, setFieldValue)
                          }
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="recruiter_email" />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col md="4">
                      <FormGroup>
                        <Label for="check_in"> Check In* </Label>
                        <Input
                          className={loading ? "placeholder-wave bg-light" : ""}
                          id="check_in"
                          name="check_in"
                          placeholder=""
                          type="date"
                          value={values.check_in}
                          onChange={(e) =>
                            this.handleInputChange(e, setFieldValue)
                          }
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="check_in" />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col md="4">
                      <FormGroup>
                        <Label for="check_out"> Check Out* </Label>
                        <Input
                          className={loading ? "placeholder-wave bg-light" : ""}
                          id="check_out"
                          name="check_out"
                          placeholder=""
                          type="date"
                          value={values.check_out}
                          onChange={(e) =>
                            this.handleInputChange(e, setFieldValue)
                          }
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="check_out" />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col md="4">
                      <FormGroup>
                        <Label for="contract_start"> Contract Start* </Label>
                        <Input
                          className={loading ? "placeholder-wave bg-light" : ""}
                          id="contract_start"
                          name="contract_start"
                          placeholder=""
                          type="date"
                          value={values.contract_start}
                          onChange={(e) =>
                            this.handleInputChange(e, setFieldValue)
                          }
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="contract_start" />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col md="4">
                      <FormGroup>
                        <Label for="contract_end"> Contract End* </Label>
                        <Input
                          className={loading ? "placeholder-wave bg-light" : ""}
                          id="contract_end"
                          name="contract_end"
                          placeholder=""
                          type="date"
                          value={values.contract_end}
                          onChange={(e) =>
                            this.handleInputChange(e, setFieldValue)
                          }
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="contract_end" />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <br />

                  <Button type="submit" color="success">
                    Submit
                  </Button>
                  {false && (
                    <div className={"row"}>
                      <div className={"col-12"}>
                        <code>
                          <pre>Values: {JSON.stringify(values, null, 2)}</pre>
                        </code>
                      </div>
                      <div className={"col-12"}>
                        <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
                      </div>
                      <div className={"col-12"}>
                        <pre>Touched: {JSON.stringify(touched, null, 2)}</pre>
                      </div>
                    </div>
                  )}
                </form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </div>
    );
  }
}
