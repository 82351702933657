import React, { Component } from 'react';
import Card from 'reactstrap/lib/Card'
import CardBody from 'reactstrap/lib/CardBody';
import { Header } from '@evenlogics/whf-ra-components'
import { FormGenerator } from '@evenlogics/whf-form-generator';
import api from '@evenlogics/whf-api'

export default class Add extends Component {

    state = {
        fields: {
            buffer: 0,
            email: ''
        },
        settingLoading: true
    }

    componentDidMount() {

        api.request('get', '/settings').then((res) => {
            this.setState({ fields: { buffer: parseInt(res.buffer), email: res.email }, settingLoading: false }, () => {

            })
        }).catch(() => {

        })


    }


    render() {




        const passwordFields = {

            current_password: {
                type: 'password',
                label: 'Current Password',
                required: true,
                name: 'current_password',
                col: 3,

            },
            password: {
                type: 'password',
                label: 'New Password',
                required: true,
                name: 'password',
                col: 3
            },
            password_confirmation: {
                type: 'password',
                label: 'Confirm Password',
                required: true,
                name: 'password_confirmation',
                col: 3,
                oneOf: 'password'
            },
        };

        const generalSettingFields = {
            buffer: {
                type: 'number',
                label: 'Buffer (%)',
                required: true,
                name: 'buffer',
                col: 3,
                min:0


            },
            email: {
                type: 'email',
                label: 'Email',
                required: true,
                name: 'email',
                col: 3,

            },
        }

        const { fields, settingLoading } = this.state
        console.log({ fields })
        return (
            <div>
                <Card className="animated fadeIn">
                    <Header title="Settings" />
                    <CardBody>
                        {!settingLoading &&
                            <div className='card p-3'>
                                <h5>General Settings</h5>

                                <FormGenerator
                                    initialValues={{ ...fields }}
                                    targetEntity="settings"
                                    fields={generalSettingFields}
                                    name="general-settings"
                                    redirect="settings"
                                />
                            </div>


                         }

                        <div className='card p-3'>
                            <h5>Update Password</h5>

                            <FormGenerator
                                targetEntity="update-password"
                                fields={passwordFields}

                                name="update-password"
                                // debug={true}
                                redirect="settings"
                            />
                        </div>



                    </CardBody>
                </Card>
            </div>
        )
    }
}
