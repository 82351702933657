import Dashboard from "./../components/Dashboard";
import HotelAdd from "../components/Hotels/Add";
import HotelList from "../components/Hotels/List";

import BookingsList from "../components/Bookings/List";

import EmployeesAdd from "../components/Employees/Add";
import EmployeesList from "../components/Employees/List";

import EmailAdd from "../components/EmailTemplates/Add";
import EmailList from "../components/EmailTemplates/List";

import SmsAdd from "../components/SmsTemplates/Add";
import SmsList from "../components/SmsTemplates/List";

import SettingAdd from "../components/Settings/Add";

import AvailibilityAdd from "../components/AvailibilityForm/Add";
import AvailibilityList from "../components/AvailibilityForm/List";
import Responses from "../components/AvailibilityForm/Responses";
import EmployeeDetails from "../components/AvailibilityForm/EmployeeDetails";

import Confirmation from "../components/SeparateDesigns/Confirmation";
import CheckoutReceipt from "../components/SeparateDesigns/CheckoutReceipt";
import Thankyou from "../components/SeparateDesigns/Thankyou";

import HotelArchives from "../components/Archives/HotelArchives";
import BookingArchives from "../components/Archives/BookingArchives";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    isPublic: false,
    exact: true,
  },
  {
    path: "/hotels/list",
    name: "hotelAdd",
    component: HotelList,
    isPublic: false,
    exact: true,
  },
  {
    path: "/hotels/add",
    name: "hotelList",
    component: HotelAdd,
    isPublic: false,
    exact: true,
  },
  {
    path: "/hotels/:id/edit",
    name: "hotelEdit",
    component: HotelAdd,
    isPublic: false,
    exact: true,
  },
  {
    path: "/bookings/list",
    name: "bookingsList",
    component: BookingsList,
    isPublic: false,
    exact: true,
  },
  {
    path: "/employees/list",
    name: "employeesList",
    component: EmployeesList,
    isPublic: false,
    exact: true,
  },
  {
    path: "/employees/add",
    name: "employeesAdd",
    component: EmployeesAdd,
    isPublic: false,
    exact: true,
  },
  {
    path: "/employees/:id/edit",
    name: "employeesEdit",
    component: EmployeesAdd,
    isPublic: false,
    exact: true,
  },
  {
    path: "/email-templates/list",
    name: "employeesList",
    component: EmailList,
    isPublic: false,
    exact: true,
  },
  {
    path: "/email-templates/:id/edit",
    name: "employeesEdit",
    component: EmailAdd,
    isPublic: false,
    exact: true,
  },
  {
    path: "/messages/list",
    name: "smsList",
    component: SmsList,
    isPublic: false,
    exact: true,
  },
  {
    path: "/messages/:id/edit",
    name: "smsEdit",
    component: SmsAdd,
    isPublic: false,
    exact: true,
  },

  {
    path: "/settings",
    name: "settings",
    component: SettingAdd,
    isPublic: false,
    exact: true,
  },

  {
    path: "/availability-form/add",
    name: "availibilityAdd",
    component: AvailibilityAdd,
    isPublic: false,
    exact: true,
  },
  {
    path: "/availability-form/list",
    name: "availibilityList",
    component: AvailibilityList,
    isPublic: false,
    exact: true,
  },
  {
    path: "/availability-form/list/responses/:id",
    name: "responses",
    component: Responses,
    isPublic: false,
    exact: true,
  },
  {
    path: "/availability-form/list/responses/:id/hotel/:id",
    name: "employees",
    component: EmployeeDetails,
    isPublic: false,
    exact: true,
  },
  {
    path: "/archives/hotels",
    name: "hotelArchives",
    component: HotelArchives,
    isPublic: false,
    exact: true,
  },
  {
    path: "/archives/bookings",
    name: "bookingArchives",
    component: BookingArchives,
    isPublic: false,
    exact: true,
  },
  {
    path: "/bookings/:bookingid/hotels/:hotelid/response/:yesnopartial",
    name: "confirmation",
    component: Confirmation,
    isPublic: true,
    exact: true,
  },
  {
    path: "/modify-bookings/:bookingid/hotels/:hotelid/response/:yesnopartial",
    name: "confirmation",
    component: Confirmation,
    isPublic: true,
    exact: true,
  },
  {
    path: "/checkoutreceipt/:base64",
    name: "checkoutReceipt",
    component: CheckoutReceipt,
    isPublic: true,
    exact: true,
  },
  {
    path: "/thankyou",
    name: "thankyou",
    component: Thankyou,
    isPublic: true,
    exact: true,
  },
];

export default routes;
