import React from "react";
import RemoteTable from "@evenlogics/whf-remote-table";
import { toast } from "react-toastify";
import api from "@evenlogics/whf-api";
import { Collapse, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import {isUndefined} from "lodash";

const PartialAvailability = ({ cell, row }) => {
  console.log("cell", row);

  const [expand, setExpand] = React.useState(false);
  const handleClick = () => {
    setExpand(!expand);
  };
  if (cell === 1) {
    return (
      <span style={{ fontSize: "15px" }} className="badge badge-success">
        Yes
      </span>
    );
  } else if (cell === 2) {
    return (
      <span style={{ fontSize: "15px" }} className="badge badge-danger">
        No
      </span>
    );
  } else if (cell === 3) {
    let singleDate = 0;
    for (let i = 0; i < row?.partial_dates.length; i++) {
      if (row.partial_dates[i].start_date === row.partial_dates[i].end_date) {
        singleDate = 1;
      }
    }

    return (
      <>
        <Button
          onClick={handleClick}
          aria-controls="zz"
          aria-expanded={expand}
          variant={expand ? "outline-warning" : "outline-primary"}
          className={"mb-2"}
        >
          Partial Availability
        </Button>
        <Collapse in={expand}>
          <div id="zz" className="card" style={{ width: "300px" }}>
            <table className="table table-borderless ">
              <thead>
                <th>Start Date</th>
                <th>End Date</th>
              </thead>
              <tbody>
                {row.partial_dates.map((date, i) => {
                  if (date.start_date !== date.end_date) {
                    return (
                      <tr key={i}>
                        <td>{date.start_date}</td>
                        <td>{date.end_date}</td>
                      </tr>
                    );
                  } else {
                    return null;
                  }
                })}
              </tbody>
            </table>
            <div>
              {singleDate === 1 ? (
                <table className="table table-borderless ">
                  <thead>
                    <th>Single Dates</th>
                  </thead>
                  <tbody>
                    {row.partial_dates.map((date, i) => {
                      if (date.start_date === date.end_date) {
                        return (
                          <tr key={i}>
                            <td>{date.start_date}</td>
                          </tr>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </tbody>
                </table>
              ) : null}
            </div>
          </div>
        </Collapse>
      </>
    );
  } else {
    return (
      <span style={{ fontSize: "15px" }} className="badge badge-warning">
        Pending
      </span>
    );
  }
};

/* The main Component: */

const Responses = (props) => {
  console.log(props.location.state, "lllll");
  const defaultSorted = [{ dataField: "id", order: "desc" }];
  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "name",
      text: "Hotel Name",
      sort: true,
    },
    {
      dataField: "response_flag",
      text: "Response", //response id 1 name it
      sort: true,
      formatter: (cell, row) => {
        return <PartialAvailability cell={cell} row={row} />;
      },
    },
    {
      dataField: "address",
      text: "Hotel Address",
      sort: true,
    },
    (props.location.state === 1 || props.location.state === 3) &&{
      // dataField: "response_flag",
      text: "Action",
      sort: true,
      formatter: (cell, row) => {
        if (row.response_flag !== 2 && row.response_flag !== null) {
          return (
            <div>
            
              <button
                className="btn btn-primary"
                style={{ background: "#848484" }}
                onClick={() => selectHotel(row)}
              >
                Select Hotel
              </button>
            </div>
          );
        } else {
          return null;
        }
      },
    },
  ];

  const selectHotel = (row) => {
    if (row?.response_flag === 2 || row?.response_flag === null) {
      toast.error("Hotel is not available");
    } else {
      Swal.showLoading();
      api
        .request(
          "get",
          `/bookings/${props.match.params.id}/hotels/${row.id}/preview`
        )
        .then((res) => {
          Swal.close();
          if (res?.data) {
            props.history.push(
              `/availability-form/list/responses/${props.match.params.id}/hotel/${row.id}`
            );
          } else {
            toast.error(
              "No rate exist against this hotel for current month, Please first go back to hotel page and add the rate for this hotel."
            );
          }
        })
        .catch((error) => {
          Swal.close();
          toast.error(error.response?.data?.message);
        });
    }
  };
  return (
    <RemoteTable
      entity={isUndefined(props.location.state) ? `requests/${props.match.params.id}/responses` : 
      `requests/${props.match.params.id}/responses?response=${props.location.state}`}
      customEntity={isUndefined(props.location.state) ? `requests/${props.match.params.id}/responses` : 
      `requests/${props.match.params.id}/responses?response=${props.location.state}`}
      columns={columns}
      sort={defaultSorted}
      hideEdit={true}
      hideDetail={true}
      disableDelete={true}
      hideActionCol={true}
    />
  );
};

export default Responses;
