import React, { useState,useRef } from "react";
import RemoteTable from "@evenlogics/whf-remote-table";
import { toast } from "react-toastify";
import api from "@evenlogics/whf-api";
import Swal from "sweetalert2";
import { Button,Row,Col} from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import NamesArray from "../common/NamesArray";
import Form from 'react-bootstrap/Form';
import Badge from "react-bootstrap/Badge";
const List = ({ archives }) => {
  const [update, setUpdate] = useState(false);
  const [showModal,setShowModal]=useState(false)
  const rowData=useRef(null)
  const checkinRef=useRef(null)
  const checkoutRef=useRef(null)
  const toggleModal=()=>setShowModal(!showModal)
  const defaultSorted = [{ dataField: "id", order: "desc" }];
  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "employees",
      text: "Employees",
      sort: true,
      formatter: (cell, row) => {
        return <NamesArray confirmedBookings={true} cell={cell} row={row} />;
      },
    },
    {
      dataField: "name",
      text: "Hotel Name",
      sort: true,
      formatter: (cell, row) => {
        return <span>{row.hotel.name}</span>;
      },
    },
    {
      dataField: "check_in",
      text: "Check-In Date",
      sort: true,
    },
    {
      dataField: "check_out",
      text: "Check-Out Date",
      sort: true,
    },

    {
      dataField: "address",
      text: "Location",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: (cell, row) => {
        if(cell===2){
          return  <Badge pill bg="success" className="text-white">
          Active(Confirmed)
        </Badge> 
        }
        if(cell===4){
          return <Badge pill bg="warning" className="text-white">
          In Modification
        </Badge> 
        }
      }
    },
    {
      dataField: "cancelModify",
      text: "Cancel/Modify",
      sort: true,
      formatter: (cell, row) => {
        if(row.status!==4){
          return <span>
          
          <Button variant="danger" onClick={()=>cancelBooking(row)} >Cancel</Button>{'  '}
          <Button variant="warning" onClick={()=>openBookingModal(row)} >Modify</Button>
        </span>;
        }
        
      },
    },
  ];

  const openBookingModal=(row)=>{
    rowData.current=row
   toggleModal()
   setTimeout(() => {
     checkinRef.current.value=row.check_in
     checkoutRef.current.value=row.check_out
   }, 100); 

  }

  const cancelBooking = async ({ id }) => {
    try {
      Swal.showLoading();
      const data = await api.request('get', `/bookings/${id}/cancel`)
      console.log(data)
      setUpdate(!update);
      Swal.close();
    } catch (error) {
      Swal.close();
    }
  }

  const modifyBooking=async (check_in,check_out)=>{
    try {
      Swal.showLoading();
      const payload={check_in,check_out,_method:'PUT'}
      const data = await api.request('post', `/requests/${rowData.current.id}/modify`,payload)
      console.log(data)
      setUpdate(!update);
      Swal.close();
      setShowModal(false)
      toggleModal()
    } catch (error) {     
      toast.error( Object.values(error.response.data.errors).toString());
      Swal.close();
    }
  }

  const handleSelectedRows = (selectedRows) => {
    if (selectedRows.length > 0) {
      Swal.showLoading();
      const payload = { bookings: selectedRows.map((row) => row.id) };

      let method = "post";
      let url = "/bookings/export";

      api
        .request(method, url, payload)
        .then((res) => {
          console.log("res :>> ", res);
          const atob = (base64) => {
            return Buffer.from(base64, "base64").toString("binary");
          };
          const blob = new Blob([atob(res.base64)], {
            type: "data:application/octet-stream;base64",
          });
          let url = URL.createObjectURL(blob);
          let link = document.createElement("a");
          link.href = url;
          link.download = "bookings.csv";
          link.click();
          Swal.close();
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          console.log("error :>> ", error);
        });
    } else {
      toast.error("Please select at least one booking");
    }
  };
  const handleArchive = (row) => {
    Swal.showLoading();
    let method = "get";
    let url = archives
      ? `/bookings/${row.id}/unarchive`
      : `/bookings/${row.id}/archive`;

    api
      .request(method, url)
      .then((res) => {
        console.log("res :>> ", res);
        Swal.fire({ title: `${res.message}`, icon: "success" });
        setUpdate(!update);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log("error :>> ", error);
      });
  };
  return (
    <>
     <Modal show={showModal} onHide={()=>setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Modify booking</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <Row>
            <Col md="4">
              <Form.Group className="mb-3" >
                <Form.Label>Check-in Date </Form.Label>
                <Form.Control type="date" ref={checkinRef} />
              </Form.Group>
            </Col>

            <Col md="4">
              <Form.Group className="mb-3" >
                <Form.Label>Check-out Date </Form.Label>
                <Form.Control type="date"  ref={checkoutRef} />
              </Form.Group>
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setShowModal(false)}>Close</Button>
          <Button variant="primary" onClick={()=>{modifyBooking(checkinRef.current.value,checkoutRef.current.value)}}>Save</Button>
        </Modal.Footer>
      </Modal>
    
    <RemoteTable
      entity={archives ? "archived-bookings" : "bookings"}
      columns={columns}
      sort={defaultSorted}
      hideEdit={true}
      hideDetail={true}
      disableDelete={true}
      getCustomRows={{
        callback: handleSelectedRows,
        heading: "Export Selected Data",
      }}
      selectedRows={true}
      Query={update}
      customButton={{
        name: archives ? "Unarchive" : "Archive",
        color: "dark",
        callback: handleArchive,
      }}
      />
      </>
  );
};

export default List;
