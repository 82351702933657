import React, { useState } from "react";
import RemoteTable from "@evenlogics/whf-remote-table";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import { Header } from "@evenlogics/whf-ra-components";
import { Collapse, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import api from "@evenlogics/whf-api";
import {Badge} from "reactstrap";

const List = ({ archives }) => {
  const [update, setUpdate] = useState(false);

  const columns = [
    { dataField: "id", text: "Id", align: "center", sort: true },
    { dataField: "name", text: "Name", align: "center", sort: true },
    {
      dataField: "emails", text: "Email", align: "center", sort: true, formatter: (cell, row) => {
        return (
          <>
          {row.emails.map((email, index) => {
            return <Badge color="secondary" key={index} style={{padding: "2%", margin: "1%", fontSize: 12}}>
              {email}
            </Badge>
          })}
          </>
        );
      },
    },
    { dataField: "tax_rate", text: "Tax Rate", align: "center", sort: true },
    { dataField: "brand", text: "Brand", align: "center", sort: true },
    {dataField: "brand_loyalty_number", text: "Brand Loyalty Number", align: "center", sort: true},
    { dataField: "address", text: "Address", align: "center", sort: true },
    {
      dataField: "hotel_rates",
      text: "Hotel Rates",
      align: "center",
      formatter: (cell, row) => {
        return <RateList cell={cell} row={row} />;
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];
  const handleArchive = (row) => {
    Swal.showLoading();
    let method = "get";
    let url = archives
      ? `/hotels/${row.id}/unarchive`
      : `/hotels/${row.id}/archive`;

    api
      .request(method, url)
      .then((res) => {
        console.log("res :>> ", res);
        Swal.fire({ title: `${res.message}`, icon: "success" });
        setUpdate(!update);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log("error :>> ", error);
      });
  };

  return (
    <div>
      <Card className="animated fadeIn">
        <Header title="Hotels" />
        <CardBody>
          <RemoteTable
            entity={archives ? "archived-hotels" : "hotels"}
            customEntity="hotels"
            columns={columns}
            sort={defaultSorted}
            showDetail={false}
            hideDetail={true}
            hideEdit={archives ? true : false}
            disableDelete={true}
            customButton={{
              name: archives ? "Unarchive" : "Archive",
              color: "dark",
              callback: handleArchive,
            }}
            // hideSearch={true}
            addRoute={!archives && "/hotels/add"}
            Query={update}
          // hideActionCol={archives}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default List;

function RateList(props) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => setOpen(!open)}
        aria-controls="rr"
        aria-expanded={open}
        variant={open ? "outline-warning" : "outline-primary"}
        className={"mb-2"}
      >
        {open ? (
          <i class="fa-solid fa-chevrons-up"></i>
        ) : (
          <i class="fa-solid fa-chevrons-down"></i>
        )}
      </Button>
      <Collapse in={open}>
        <div id="rr" className="card" style={{ width: "300px" }}>
          <table className="table table-borderless ">
            <thead>
              <th>Rate</th>
              <th>Start Date</th>
              <th>End Date</th>
            </thead>
            <tbody>
              {props.cell.map((c, i) => {
                return (
                  <tr key={i}>
                    <td>{c.rate}</td>
                    <td>{c.start_date}</td>
                    <td>{c.end_date}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Collapse>
    </>
  );
}
