import React, { Component } from "react";
import { Formik, ErrorMessage } from "formik";
import Geosuggest from "react-geosuggest";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import { Header } from "@evenlogics/whf-ra-components";
import * as yup from "yup";
import { Row, Col } from "react-bootstrap";
import { Label, FormGroup, Input, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import api from "@evenlogics/whf-api";
import Swal from "sweetalert2";
import AsyncSelect from "react-select/async";

export default class Hotel extends Component {
  constructor(props) {
    super(props);
    this.geosuggestEl = React.createRef();
    this.state = {
      fields: {
        employees: {
          value: [],
        },

        hotels: {
          value: []
        },

        address: {
          value: "",
        },
        longitude: {
          value: "",
        },
        latitude: {
          value: "",
        },

        check_in: {
          value: "",
        },
        check_out: {
          value: "",
        },
      },
      loading: false,
      modal: false,
      res: {}
    };
  }

  //   componentDidMount(){
  //     if (this.props.match.params.id) {
  //       this.setState({ ...this.state, loading: true })
  //       api.request('get', `/employees/${this.props.match.params.id}`)
  //           .then((res) => {
  //               let updatedState = this.state

  //               updatedState.fields.check_in.value= res.data.check_in
  //               updatedState.fields.check_out.value= res.data.check_out

  //               updatedState.fields.address.value= res.data.address
  //               updatedState.fields.latitude.value= res.data.latitude
  //               updatedState.fields.longitude.value= res.data.longitude

  //               updatedState.loading=false
  //               this.setState({ ...updatedState })
  //               // console.log('res :>> ', res);

  //           }).catch((error) => {
  //               let updatedState = this.state
  //               updatedState.loading=false
  //               this.setState({ ...updatedState })
  //               // console.log('error :>> ', error);
  //           })
  //   }
  //   }

  handleInputChange = (e, setFieldValue) => {
    let updatedState = { ...this.state };
    if (
      e.target.type === "text" ||
      e.target.type === "date" ||
      e.target.type === "number"
    ) {
      //   updatedState.fields[e.target.name].value = e.target.value
      setFieldValue(e.target.name, e.target.value);
    }

    if (e.target.type === "geoSuggest") {
      if (e.target?.value?.description) {
        // updatedState.fields.address.value = e.target.value.description
        // updatedState.fields.latitude.value = e.target.value.location.lat.toString()
        // updatedState.fields.longitude.value = e.target.value.location.lng.toString()
        setFieldValue("address", e.target.value.description);
        setFieldValue("latitude", e.target.value.location.lat.toString());
        setFieldValue("longitude", e.target.value.location.lng.toString());
      }
    }

    this.setState({ ...updatedState }, () => {});
  };

  loadOptionsHotel = (inputValue, callback, res) => {
        let options = res.data?.map((row) => {
          return { label: row.name, value: row.id };
        });
        callback(options);
  };
  asyncSelectHanlderHotel = (val, prevVals, setFieldValue) => {
    if (!val) {
      val = [];
    }
    setFieldValue("hotels", [...val]);
    // let updatedState = { ...this.state }
    // updatedState.fields.gym_sessions.value = plans
    // this.setState({ ...updatedState })
  };

  submitHotel = (values) => {
    Swal.showLoading();

    const payload = {
      employees: this.state.res.request.employees.map((emp) => emp),
      hotels: values.hotels.map((hotel) => hotel.value),
      check_in: this.state.res.request.check_in,
      check_out: this.state.res.request.check_out,

      address: this.state.res.request.address,
      latitude: this.state.res.request.latitude,
      longitude: this.state.res.request.longitude,
    };

    let method = "post";
    let url = "/bookings";

    api
      .request(method, url, payload)
      .then((res) => {
        Swal.fire({ title: "Saved!", icon: "success" });
        this.setState({model: false})
        this.props.history.push("/availability-form/list");
      })
      .catch((error) => {
        this.showError(error);
        console.log("error :>> ", error);
      });
  };

   toggle = () => this.setState({modal: !this.state.modal});

  submit = (values) => {
    Swal.showLoading();

    const payload = {
      employees: values.employees.map((emp) => emp.value),
      check_in: values.check_in,
      check_out: values.check_out,

      address: values.address,
      latitude: values.latitude,
      longitude: values.longitude,
    };

    let method = "post";
    let url = "/bookings/hotels";

    api
      .request(method, url, payload)
      .then((res) => {
        Swal.close()
        this.setState({modal: true, res: res})
        //Swal.fire({ title: "Saved!", icon: "success" }, this.setState({modal: true}));
        //this.props.history.push("/availability-form/list");
      })
      .catch((error) => {
        this.showError(error);
        console.log("error :>> ", error);
      });
  };
  showError = (error) => {
    console.log(error.response.status);
    if (error.response.status === 422) {
      let { errors } = error.response.data;
      if (errors === undefined) {
        if (error.response.data.message) {
          Swal.fire({ title: error.response.data.message, icon: "error" });
          return;
        }
        Swal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
        });
        return;
      }
      try {
        let errorMsg = "";
        Object.keys(errors).forEach((key) => {
          errorMsg = errorMsg + errors[key][0] + "\n";
        });
        Swal.fire("Validation Failed", errorMsg, "error");
      } catch (e) {
        Swal.fire({ title: "Error!", icon: "error" });
      }
    } else {
      Swal.fire("Opps! Something went wrong", "", "error");
    }
  };

  loadOptions = (inputValue, callback) => {
    api
      .request("get", "/employees/active?limit=10000000")
      .then((res) => {
        let options = res.data?.map((row) => {
          return { label: row.name, value: row.id };
        });
        callback(options);
      })
      .catch((error) => {
        callback([]);
      });
  };
  asyncSelectHanlder = (val, prevVals, setFieldValue) => {
    if (!val) {
      val = [];
    }
    setFieldValue("employees", [...val]);
    // let updatedState = { ...this.state }
    // updatedState.fields.gym_sessions.value = plans
    // this.setState({ ...updatedState })
  };

  render() {
    const { fields } = this.state;

    const initialValues = {
      employees: fields.check_out.value,

      check_in: fields.check_in.value,
      check_out: fields.check_out.value,

      address: fields.address.value,
      latitude: fields.latitude.value,
      longitude: fields.longitude.value,
    };

    const initialValuesHotel = {
      hotels: ""
    };

    const validationSchemaHotel = () => {
      return yup.object().shape({
        hotels: yup
          .array()
          .required("Check in is required!")
          .min(1, "Atleast one Hotel is required!"),
      });
    };

    const validationSchema = () => {
      return yup.object().shape({
        employees: yup
          .array()
          .required("Check in is required!")
          .min(1, "Atleast one employee is required!"),
        check_in: yup.string().required("Check in is required!"),
        check_out: yup.string().required("Check out is required!"),

        address: yup.string().required("Address is required!"),
        latitude: yup.string().required("latitude is required!"),
        longitude: yup.string().required("longitude is required!"),
      });
    };

    const { loading } = this.state;
    return (
      <div>
        <Card className="animated fadeIn">
          <Header title="Employees" />
          <CardBody>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={this.submit}
              enableReinitialize={true}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label for="Employees"> Employees </Label>

                        <AsyncSelect
                          isMulti
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 20000,
                            }),
                          }}
                          isSearchable
                          loadOptions={(inputValue, callback) =>
                            this.loadOptions(inputValue, callback)
                          }
                          defaultOptions
                          // value={null}
                          onChange={(val) =>
                            this.asyncSelectHanlder(
                              val,
                              values.employees,
                              setFieldValue
                            )
                          }
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="employees" />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col md="6">
                      <FormGroup>
                        <Label for="address"> Address </Label>
                        <Geosuggest
                          initialValue={values.address}
                          ref={this.geosuggestEl}
                          onSuggestSelect={(val) => {
                            this.geosuggestEl.current.blur();
                            this.handleInputChange(
                              {
                                target: {
                                  type: "geoSuggest",
                                  value: val,
                                  name: "address",
                                },
                              },
                              setFieldValue
                            );
                            console.log(val);
                          }}
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="address" />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col md="6">
                      <FormGroup>
                        <Label for="check_in"> Check In </Label>
                        <Input
                          className={loading ? "placeholder-wave bg-light" : ""}
                          id="check_in"
                          name="check_in"
                          placeholder=""
                          type="date"
                          value={values.check_in}
                          onChange={(e) =>
                            this.handleInputChange(e, setFieldValue)
                          }
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="check_in" />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col md="6">
                      <FormGroup>
                        <Label for="check_out"> Check Out </Label>
                        <Input
                          className={loading ? "placeholder-wave bg-light" : ""}
                          id="check_out"
                          name="check_out"
                          placeholder=""
                          type="date"
                          value={values.check_out}
                          onChange={(e) =>
                            this.handleInputChange(e, setFieldValue)
                          }
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="check_out" />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <br />

                  <Button type="submit" color="success">
                    Submit
                  </Button>
                  {false && (
                    <div className={"row"}>
                      <div className={"col-12"}>
                        <code>
                          <pre>Values: {JSON.stringify(values, null, 2)}</pre>
                        </code>
                      </div>
                      <div className={"col-12"}>
                        <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
                      </div>
                      <div className={"col-12"}>
                        <pre>Touched: {JSON.stringify(touched, null, 2)}</pre>
                      </div>
                    </div>
                  )}
                </form>
              )}
            </Formik>
          </CardBody>
        </Card>

        <div>
      <Modal isOpen={this.state.modal} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle}>Select Hotels</ModalHeader>
        <ModalBody>
        <Formik
              initialValues={initialValuesHotel}
              validationSchema={validationSchemaHotel}
              onSubmit={this.submitHotel}
              enableReinitialize={true}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <Label for="Hotels"> Hotels </Label>

                        <AsyncSelect
                          isMulti
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 20000,
                            }),
                          }}
                          isSearchable
                          loadOptions={(inputValue, callback) =>
                            this.loadOptionsHotel(inputValue, callback, this.state.res)
                          }
                          defaultOptions
                          // value={null}
                          onChange={(val) =>
                            this.asyncSelectHanlderHotel(
                              val,
                              values.hotels,
                              setFieldValue
                            )
                          }
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="hotels" />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <br />

                  <Button type="submit" color="success">
                    Submit
                  </Button>
                  {false && (
                    <div className={"row"}>
                      <div className={"col-12"}>
                        <code>
                          <pre>Values: {JSON.stringify(values, null, 2)}</pre>
                        </code>
                      </div>
                      <div className={"col-12"}>
                        <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
                      </div>
                      <div className={"col-12"}>
                        <pre>Touched: {JSON.stringify(touched, null, 2)}</pre>
                      </div>
                    </div>
                  )}
                </form>
              )}
            </Formik>
        </ModalBody>
      </Modal>
    </div>
      </div>
    );
  }
}
