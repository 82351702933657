import React from "react";

const Thankyou = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: "9999",
        margin: "0",
        padding: "1rem",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          top: "50%",
          left: "0",
          // transform: "translate(-50%, -50%)",
          position: "relative",
        }}
        className="container"
      >
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <h1 style={{ userSelect: "none" }}>
                  Thank you for submitting your response
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Thankyou;
