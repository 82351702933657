import customNav from "./config/nav";
import "./styles/style.scss";
// import TheLayout from "./components/TheLayout";
global.customNavGlobal = customNav;
global.customRoutes = require("./config/routes");
global.customModules = require("./config/modules");
global.customLogin = false;
global.customNavElements = false;
require(`@evenlogics/whf-reactadmin`);
//   global.customLayout = TheLayout;
