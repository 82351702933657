import React, { useEffect, useState, useRef } from "react";
import { Alert } from "react-bootstrap";
import { Button } from "react-bootstrap";
import api from "@evenlogics/whf-api";
import { toast } from "react-toastify";

const Dialog = (props) => {
  const [confirm, setConfirm] = useState("");
  const [visible, setVisible] = useState(true);
  const [showContent, setShowContent] = useState(false);
  const [message, setMessage] = useState("");
  const isModify=useRef(false)
  useEffect(() => {
    let url = props.match.url;
    // extracting the response

    let urlArray = url.split("/");
    isModify.current=urlArray[1]==='modify-bookings'
    let hotelsId = urlArray[urlArray.length - 3];
    let bookingsId = urlArray[urlArray.length - 5];
    let responseName = urlArray[urlArray.length - 1];
    responseName = responseName === "yes" ? "confirm" : "reject";
    api
      .request(
        "get",
        `/public/bookings/${bookingsId}/hotels/${hotelsId}/${isModify.current?'modify-response':'response'}` // modify-response
      )
      .then(() => {
        setConfirm(responseName);
        setShowContent(true);
      })
      .catch((err) => {
        if (err.response?.status === 404) {
          setVisible(false);
          // toast.error("Invalid Request");
          setMessage("Invalid Request");
          setShowContent(true);
        }
        if (err.response?.status === 422) {
          setVisible(false);
          toast.error(err.response.data.message);
          setMessage(err.response.data.message);
          setShowContent(true);
        }
      });
  }, [props.match.url]);
  const handleConfirm = () => {
    let url = props.match.url;
    // extracting the responses id from url
    let urlArray = url.split("/");
    let bookingsId = urlArray[urlArray.length - 5];
    let responseName = urlArray[urlArray.length - 1];
    let hotelsId = urlArray[urlArray.length - 3];
    api
      .request("POST", `/public/bookings/${isModify.current?'modify-response':'response'}`, {
        booking_id: bookingsId,
        hotel_id: hotelsId,
        response: responseName,
      })
      .then((res) => {
        setVisible(false);
        setShowContent(false);
        toast.success(res.message);
        props.props.history.push("/thankyou");
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };
  const handleCancel = () => {
    setVisible(false);
    setShowContent(false);
    props.props.history.push("/thankyou");
  };

  return (
    <>
      {visible && showContent && (
        <Alert variant="success">
          <Alert.Heading>Confirm Availability</Alert.Heading>
          {confirm && <p>Are you sure, you want to {confirm} this booking?</p>}
          <hr />
          <div className="d-flex justify-content-end">
            <Button
              className="mr-2"
              variant="outline-success"
              onClick={handleConfirm}
            >
              {confirm === "confirm" ? "Confirm" : "Reject"}
            </Button>
            <Button variant="outline-danger" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </Alert>
      )}
      {!visible && showContent && (
        <Alert variant="danger">
          <Alert.Heading>Error</Alert.Heading>
          <p>{message}</p>
        </Alert>
      )}
    </>
  );
};

export default Dialog;
