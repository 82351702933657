import React from "react";
import RemoteTable from "@evenlogics/whf-remote-table";
import Swal from "sweetalert2";
import api from "@evenlogics/whf-api";
import { Button } from "react-bootstrap";
import NamesArray from "../common/NamesArray";
import {isEmpty} from "lodash";

const List = (props) => {
  const defaultSorted = [{ dataField: "id", order: "desc" }];
  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "employees",
      text: "Employees",
      sort: true,
      formatter: (cell, row) => {
        if (row.employees.length > 1) {
          return <NamesArray cell={cell} row={row} />;
        } else {
          return !isEmpty(row.employees) ? row.employees[0].name : ""
        }
      },
    },
    {
      dataField: "check_in",
      text: "Check-In Date",
      sort: true,
    },
    {
      dataField: "check_out",
      text: "Check-Out Date",
      sort: true,
    },

    {
      dataField: "address",
      text: "Location",
      sort: true,
    },
    // {
    //   dataField: "responses",
    //   text: "Responses",
    //   sort: true,
    //   formatter: (cell, row) => {
    //     return (
    //       <Button
    //         variant="secondary"
    //         size="sm"
    //         onClick={() => {
    //           props.history.push(`/availability-form/list/responses/${row.id}`);
    //         }}
    //       >
    //         View Responses
    //       </Button>
    //     );
    //   },

    {
      dataField: "responses",
      text: "Confirmed",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            <Button
              variant="success"
              size="sm"
              onClick={() => {
                // props.history.push(`/availability-form/list/responses/${row.id}`);
                props.history.push({
                  pathname: `/availability-form/list/responses/${row.id}`,
                  state: 1
                });
              }}
            >
              Confirmed <span style={{fontWeight: "bold"}}>({row.confirmed_count})</span>
            </Button>
          </>
        );
      },
    },

    {
      dataField: "responses",
      text: "Pending",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            <Button
              variant="secondary"
              size="sm"
              onClick={() => {
                props.history.push(`/availability-form/list/responses/${row.id}`);
              }}
            >
              Pending  <span style={{fontWeight: "bold"}}>({row.pending_count})</span>
            </Button>
          </>
        );
      },

    },

    {
      dataField: "responses",
      text: "Rejected",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            <Button
              variant="danger"
              size="sm"
              onClick={() => {
               // props.history.push(`/availability-form/list/responses/${row.id}`);
               props.history.push({
                  pathname: `/availability-form/list/responses/${row.id}`,
                  state: 2
                });
              }}
            >
              Rejected <span style={{fontWeight: "bold"}}>({row.rejected_count})</span>
            </Button>
          </>
        );
      },
    },

    {
      dataField: "responses",
      text: "Partially",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            <Button
              variant="primary"
              size="sm"
              onClick={() => {
               // props.history.push(`/availability-form/list/responses/${row.id}`);
               props.history.push({
                  pathname: `/availability-form/list/responses/${row.id}`,
                  state: 3
                });
              }}
            >
              Partially <span style={{fontWeight: "bold"}}>({row.partial_count})</span> 
            </Button>
          </>
        );
      },

    },
  ];

  const sendNotification = (row) => {
    Swal.showLoading();
    api
      .request("post", `/requests/${row.id}/resend`)
      .then((res) => {
        console.log(res);
        Swal.fire({ title: `${res.message}`, icon: "success" });
      })
      .catch((error) => {
        Swal.fire({ title: `${error.response.data.message}`, icon: "info" });
        console.log("error :>> ", error);
      });
  };
  return (
    <RemoteTable
      entity="requests"
      customEntity="requests"
      columns={columns}
      sort={defaultSorted}
      hideEdit={true}
      hideDetail={true}
      disableDelete={true}
      //   showAdvanceFilters={true}
      //   hideQuickSearch={false}
      addRoute={"/availability-form/add"}
      customButton={{
        name: "Resend Emails",
        color: "dark",
        callback: sendNotification,
      }}
    />
  );
};

export default List;
