import React, { useEffect, useState } from "react";
import { Card as CCard } from "reactstrap/lib";
import CardBody from "reactstrap/lib/CardBody";
import { Row, Col, Card } from "react-bootstrap";
import { Button } from "reactstrap";
import api from "@evenlogics/whf-api";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const EmployeeDetails = (props) => {
  const [employees, setEmployees] = useState([]);
  const [hotel, setHotel] = useState({});
  const [disableBtn, setDisableBtn] = useState(false);

  useEffect(() => {
    let url = props.match.url;
    // extracting the responses id from url
    let urlArray = url.split("/");
    let responseId = urlArray[urlArray.length - 3];
    api
      .request(
        "get",
        `/bookings/${responseId}/hotels/${props.match.params.id}/preview`
      )
      .then((res) => {
        let updatedEmployees = [];
        updatedEmployees = res.data;
        setEmployees(updatedEmployees);
        setHotel(res?.hotel);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.match.url, props.match.params.id]);

  const handleClick = () => {
    Swal.showLoading();
    setDisableBtn(true);
    let url = props.match.url;
    // extracting the responses id from url
    let urlArray = url.split("/");
    let responseId = urlArray[urlArray.length - 3];
    api
      .request("POST", `/bookings/${responseId}`, {
        _method: "PUT",
        hotel_id: props.match.params.id,
      })
      .then((res) => {
        toast.success(res?.message);
        Swal.close();
        props.history.push(`/availability-form/list`);
      })
      .catch((err) => {
        console.log("err :>> ", err);
        Swal.close();
        setDisableBtn(false);
      });
  };

  return (
    <div>
      <CCard className="animated fadeIn">
        <span
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "16px 16px 0px",
          }}
        >
          <Card.Title>Employees</Card.Title>{" "}
          <Button disabled={disableBtn} onClick={handleClick} color="success">
            Send
          </Button>
        </span>
        <hr />
        <CardBody>
          {employees?.employees?.map((employee, index) => (
            <Card key={index} style={{ padding: "1rem" }}>
              <Card.Title style={{ paddingBottom: "0" }}>
                Employee {index + 1}
              </Card.Title>
              <hr />

              <Row style={{ paddingTop: "0", marginTop: "0" }}>
                <Col md="3">
                  <br />
                  <Card.Title
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Name
                  </Card.Title>
                  <Card.Text>{employee.name}</Card.Text>
                  <Card.Title
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Email
                  </Card.Title>
                  <Card.Text>{employee.email}</Card.Text>
                </Col>
                <Col md="3">
                  <br />
                  <Card.Title
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Phone
                  </Card.Title>
                  <Card.Text>{employee.phone}</Card.Text>
                  <Card.Title
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Check In
                  </Card.Title>
                  <Card.Text>{employees?.check_in}</Card.Text>
                </Col>
                <Col md="3">
                  <br />
                  <Card.Title
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Check Out
                  </Card.Title>
                  <Card.Text>{employees?.check_out}</Card.Text>
                  <Card.Title
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Tax Rate
                  </Card.Title>
                  <Card.Text>{hotel && hotel?.tax_rate}</Card.Text>
                </Col>{" "}
                <Col md="3">
                  <br />
                  <Card.Title
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Brand Reward No.
                  </Card.Title>
                  <Card.Text>{hotel && hotel?.brand}</Card.Text>
                  <Card.Title
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Address
                  </Card.Title>
                  <Card.Text>{employee.address}</Card.Text>
                </Col>
              </Row>
              <Row style={{ paddingTop: "0", marginTop: "0" }}>
                <Col md="3">
                  <br />
                  <Card.Title
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Contract End
                  </Card.Title>
                  <Card.Text>{employee.contract_end}</Card.Text>
                </Col>
                <Col md="3">
                  <br />
                  <Card.Title
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Total Payment
                  </Card.Title>
                  <Card.Text>${hotel && hotel?.total} USD</Card.Text>
                </Col>
              </Row>
            </Card>
          ))}
        </CardBody>
      </CCard>
    </div>
  );
};

export default EmployeeDetails;
